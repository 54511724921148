export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.langsSwitcher()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const sidebar = $('[data-id="hide-to-scroll"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                sidebar.removeClass('sidebar--ontop')

                navbar.addClass('navbar--onscroll')
                sidebar.addClass('sidebar--onscroll')

                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                sidebar.removeClass('sidebar--onscroll')
                navbar.addClass('navbar--ontop')
                sidebar.addClass('sidebar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'
        const ecobar = '[data-id="eco-bar"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')

            if (!$(ecobar).hasClass('eco-bar--hidden')) {
                $(ecobar).addClass('eco-bar--hidden')
            }

            if (!body.hasClass('menu-open')) {
                body.removeClass('is-submenu-open')
                $(submenu).removeClass('submenu-open')
            }

            //Change logo on mobile
            if ($('body.menu-open').length > 0 && window.matchMedia('(max-width: 650px)').matches) {
                NavbarComponent.changeLogoColor('banner')
            } else {
                if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    NavbarComponent.changeLogoColor('classic')
                }
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
            body.addClass('is-submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
            body.removeClass('is-submenu-open')
        })
    }

    static langsSwitcher() {
        const current_lang = $('.lang__current')
        const list_langs = $('.lang__list')
        const listLang = '[data-id="list-lang"]'

        //Open List
        $(current_lang).on('click', () => {
            $(listLang).toggleClass('lang__list--open')
        })

        //Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('lang__list--open')
            setTimeout(() => {
                const currentLangCode = $('html').attr('lang')
                $('.current__lang').html(currentLangCode.substr(0, 2))
            }, '1000')
        })

        // Fix current lang
        setTimeout(() => {
            const currentLangCode = $('html').attr('lang')
            $('.current__lang').html(currentLangCode.substr(0, 2))
        }, '1000')
    }
}
